import React from 'react';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";

import './Hero.css';

const Hero = () => {

    const scrollTo = (e) => {
        e.preventDefault();
        const target = e.target.getAttribute('href');
        console.log(target);
        const location = document.querySelector(target).offsetTop;

        window.scrollTo({
            left: 0,
            top: location - 75
        });
	};

    return (
        <div className="hero" id="home-section">
            <div className="tagline">
                <div className="hero-title">Key West Funding</div>
                {/* <Typography variant="h2" sx={{fontWeight: "bold", marginBottom: "10px"}}>Key West Funding</Typography> */}
                <div className="hero-italic slogan">Your source of Home Loan Solutions.</div>
                <div className="hero-italic">Honest <span>|</span> Full Disclosure <span>|</span> No pressure</div>
            </div>
            <div className="buttonContainer">
                <motion.button className="motion-button" whileHover={{ scale: 1.15 }} whileTap={{ scale: 0.9 }}><a href="#apply-section" onClick={(e) => scrollTo(e)}>APPLY NOW</a></motion.button>
            </div>
        </div>
    )
}

export default Hero;
