import React, { useState } from 'react';
import { 
    AppBar,
    Toolbar,
    IconButton, 
    Link,
    Divider,
    SwipeableDrawer,
    List,
    ListItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import './Navbar.css';

const navLinks = [
    { name: "Home", href: "#home" },
    { name: "Offerings", href: "#offerings-section" },
    { name: "Team", href: "#team-section" },
    { name: "Apply", href: "#apply-section" },
    { name: "Contact", href: "#footer-section" },
];

const Navbar = () => {
    const [open, setOpen] = useState(false);

    const scrollTo = (e) => {
        e.preventDefault();
        console.log(e.target.getAttribute('href'));
        const target = e.target.getAttribute('href');
        const location = document.querySelector(target).offsetTop;

        window.scrollTo({
            left: 0,
            top: location - 75
        });
	};

    return(

            <AppBar className="navbar" position="fixed">
                <Toolbar className="toolbar">
                    <Link className="img-link" href="/"><img type="image/webp" src="./images/kwfunding_logo.webp" alt="KeyWest Funding" title="KeyWest Funding Logo" height="75px" width="auto" /></Link>
                        {navLinks.map((item, index) => (
                            <Link 
                                className="link" 
                                href={item.href} 
                                variant="button" 
                                underline="none" 
                                key={index} 
                                onClick={(e) => scrollTo(e)}
                                sx={{ display: { xs: 'none', md: 'block' }}}
                            >
                                {item.name}
                            </Link>
                        ))}
                    <IconButton className="icon-button" onClick={() => setOpen(true)} sx={{ display: { sm: 'block', md: 'none' }}}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <SwipeableDrawer
                    anchor="right" 
                    open={open} 
                    onOpen={() => setOpen(true)} 
                    onClose={() => setOpen(false)}
                >
                    <div
                        onClick={() => setOpen(false)}
                        onKeyPress={() => setOpen(false)}
                        role="button"
                        tabIndex={0}
                    >
                        <IconButton className="icon-button">
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {navLinks.map((item, index) => (
                            <ListItem key={index}>
                                <Link 
                                    className="drawer-link" 
                                    href={item.href} 
                                    variant="button" 
                                    underline="none" 
                                    onClick={(e) => scrollTo(e)}
                                >
                                    {item.name}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </SwipeableDrawer>
            </AppBar>
    )
}

export default Navbar;