import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import './Offerings.css';
import { typography } from '@mui/system';

const Offerings = () => {
    return (
        <div className="offerings" id="offerings-section">
            <div className="offerings-content">
                <div className="about">
                    <Typography className="about-text" variant="h5">
                        <span>KEY WEST FUNDING</span> is a family owned and operated business that has been established since 1998. <span>Key West Funding</span> is committed to the best service and rates in the mortgage business. We are honest, hard working, passionate, and will make sure we take care of all your needs. As a mortgage brokerage, we are able to provide our clients with so much more than the standard options. <span>Key West Funding</span> will always offer top tier service and will assist you through the entire mortgage process step by step. We are truly grateful for all of our current and past clients. Contact us now and allow us to assist you!
                    </Typography>
                </div>
                <div className="cards">
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h4" color="#a79a3b" sx={{ fontWeight: 'bold' }} gutterBottom>Purchase</Typography>
                            <Typography variant="body1" color="#FFFEFC">Conventional</Typography>
                            <Typography variant="body1" color="#FFFEFC">VA 100%</Typography>
                            <Typography variant="body1" color="#FFFEFC">USDA 100%</Typography>
                            <Typography variant="body1" color="#FFFEFC">First time home buyer</Typography>
                            <Typography variant="body1" color="#FFFEFC">New Construction</Typography>
                        </CardContent>
                    </Card>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h4" color="#a79a3b" sx={{ fontWeight: 'bold' }} gutterBottom>Refinance</Typography>
                            <Typography variant="body1" color="#FFFEFC">Rate and Term</Typography>
                            <Typography variant="body1" color="#FFFEFC">Cashout Refinance</Typography>
                            <Typography variant="body1" color="#FFFEFC">15, 20, 25, 30 Refinance Options</Typography>
                            <Typography variant="body1" color="#FFFEFC">FHA Stream Lines</Typography>
                            <Typography variant="body1" color="#FFFEFC">VA IRRLS</Typography>
                        </CardContent>
                    </Card>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h4" color="#a79a3b" sx={{ fontWeight: 'bold' }} gutterBottom>Loans</Typography>
                            <Typography variant="body1" color="#FFFEFC">Experienced Loan Officers</Typography>
                            <Typography variant="body1" color="#FFFEFC">Fast & Easy Approvals</Typography>
                            <Typography variant="body1" color="#FFFEFC">Highest Quality</Typography>
                            <Typography variant="body1" color="#FFFEFC">Lowest Rates in Utah</Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Offerings;
