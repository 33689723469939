import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { teamData } from '../../teamData';


import './Team.css';
import { Avatar } from '@mui/material';

const Team = () => {
    return (
        <div className="team" id="team-section">
            <div className="team-header">Meet Our Team</div>
            <div id="team-main">
                {teamData.map((item, index) => (
                    <Card className="card" key={index}>
                        <div className="card-content">
                            {item.img ? <img type="image/webp" src={item.img} alt={item.name} height="250px" /> : <div className="avatar-container"><Avatar sx={{ width: 200, height: 200}} /></div>}
                            <Typography variant="h5" color="#204e79" mt={2}>{item.name}</Typography>
                            <Typography variant="h6" color="#a79a3b" mb={2}>{item.nmls}</Typography>
                            <div className="card-social">
                                <a href={'tel:' + item.phone}><FontAwesomeIcon className="social-icon" icon="phone-alt" size="2x" /></a>
                                <a href={'mailto:' + item.email}><FontAwesomeIcon className="social-icon" icon="envelope" size="2x" /></a>
                                <a href={item.facebook} rel="noopener" target="_blank"><FontAwesomeIcon className="social-icon" icon={['fab', 'facebook-f']} size="2x" /></a>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default Team;
