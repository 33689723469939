import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { motion } from "framer-motion";

import './Apply.css';

const Apply = () => {
    return (
        <div className="apply" id="apply-section">
            <div className="apply-container">
                <Typography variant="h4" color="#204e79">Apply Now</Typography>
                <hr color="#a79a3b"/>
                <Typography variant="h5" color="#204e79" mt={4}>Please click the button to download application and email it back to us!</Typography>
                <div className="apply-btn-container">
                    <Link href="./docs/1003_form.pdf" download>
                        <motion.button className="motion-button" whileHover={{ scale: 1.15 }} whileTap={{ scale: 0.9 }}>DOWNLOAD</motion.button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Apply;
