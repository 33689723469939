import React from 'react';
import './App.css';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import { Navbar, Hero, Offerings, Team, Apply } from './components';
import eho from './img/eho.webp';

library.add(fab, faEnvelope, faPhoneAlt);

function App() {
  return (
    <div className="app">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="main" id="home">
        <div className="sections">
          <Hero />
          <Offerings />
          <Team />
          <Apply />
        </div>
        <div className="footer" id="footer-section">
          <div className="footer-left">
            <Typography variant="h6" color="#204e79">Key West Funding</Typography>
            <Typography variant="h6" color="#FFFEFC">1448 N 2000 W <br />SUITE 2 <br />CLINTON, UTAH 84015</Typography>
            <Typography variant="h6" color="#FFFEFC">NMLS#1233824</Typography>
          </div>
          <div className="footer-mid">
            <Typography id="phone" variant="h6" color="#FFFEFC"><label for="phone">Phone:</label> 801-773-5538</Typography>
            <Typography id="phone" variant="h6" color="#FFFEFC"><label for="phone">Fax:</label> 801-773-5538</Typography>
            <Typography id="email" variant="h6" color="#FFFEFC"><label>Email:</label> keywestfunding@comcast.net</Typography>
          </div>
          <div className="footer-right">
            <div className="footer-social">
              <a href="tel:801-773-5538"><FontAwesomeIcon className="social-icon" icon="phone-alt" size="2x" /></a>
              <a href="mailto:keywestfunding@comcast.net"><FontAwesomeIcon className="social-icon" icon="envelope" size="2x" /></a>
              <a href="https://www.facebook.com/keywestfunding" rel="noopener" target="_blank"><FontAwesomeIcon className="social-icon" icon={['fab', 'facebook-f']} size="2x" /></a>
            </div>
            <img type="image/webp" src={eho} alt="Equal Housing Opportunity" title="Equal Housing Opportunity Logo" height="75px" width="auto" />
          </div>
        </div>
        <div className="copyright">
            <Typography variant="h6" color="#204e79">&copy; 2021 Key West Funding Group, All Rights Reserved.</Typography>
        </div>
      </div>
    </div>
  );
}

export default App;
