export const teamData = [
    {
        name: 'Rick & Sheri Anderson',
        nmls: '#284446',
        img: './images/rick_anderson.webp',
        phone: '801-391-6160',
        email: 'keywestfunding@comcast.net',
        facebook: 'https://www.facebook.com/keywestfunding'
    },
    {
        name: 'Brandi Rich',
        nmls: '#2075970',
        img: './images/brandi_rich.webp',
        phone: '801-648-0780',
        email: 'brandianderson2001@icloud.com',
        facebook: 'https://www.facebook.com/brandi.anderson.545'
    },
    {
        name: 'Jake Anderson',
        nmls: '#1421703',
        img: './images/jake_anderson.webp',
        phone: '801-991-0347',
        email: 'jakekeywest@comcast.net',
        facebook: 'https://www.facebook.com/jakeandersonkeywestfunding'
    },
    {
        name: 'Tiffany Denny',
        nmls: '#301226',
        img: './images/tiffany_denny.webp',
        phone: '801-675-9842',
        email: 'tdenny0406@gmail.com',
        facebook: 'https://www.facebook.com/tiffany.denny47'
    },
    {
        name: 'Laura Ellison',
        nmls: '#277606',
        img: './images/laura_ellison.webp',
        phone: '801-698-2680',
        email: 'lnarnold64@gmail.com',
        facebook: 'https://www.facebook.com/laura.n.arnold.3'
    },
    {
        name: 'Denise Garza',
        nmls: '#1953627',
        img: './images/denise_garza.webp',
        phone: '801-330-0025',
        email: 'denise@dgarza.us',
        facebook: 'https://www.facebook.com/denise.garza.792'
    },
    {
        name: 'Tauna Hess',
        nmls: '#301633',
        img: './images/tauna_hess.webp',
        phone: '801-451-7671',
        email: 'thess@xmission.com',
        facebook: 'https://www.facebook.com/tauna.hess'
    },
    {
        name: 'Bob Kirkman',
        nmls: '#314428',
        img: './images/bob_kirkman.webp',
        phone: '801-499-2500',
        email: 'bob@bobkirkman.com',
        facebook: 'https://www.facebook.com/BobKirkmanMortgage'
    },
    {
        name: 'Josh Mcbride',
        nmls: '#277606',
        img: './images/josh_mcbride.webp',
        phone: '801-706-5674',
        email: 'josh@joshmcbrideonline.com',
        facebook: 'https://www.facebook.com/Josh-McBride-Office-120031448030898'
    },
    {
        name: 'Jeffery Painter',
        nmls: '#308495',
        img: './images/jeffery_painter.webp',
        phone: '801-589-1665',
        email: 'painterrealestate@gmail.com',
        facebook: 'https://www.facebook.com/jeff.painter.73'
    },
    {
        name: 'Shawna Young',
        nmls: '#1718741',
        img: './images/shawna_young.webp',
        phone: '801-814-3079',
        email: 'shawnayoung1316@comcast.net',
        facebook: 'https://www.facebook.com/shawna.young.56'
    },
    {
        name: 'Adriane LaSalle',
        nmls: '#307924',
        img: './images/adriane_lasalle.webp',
        phone: '801-726-9442',
        email: 'alasalle@q.com',
        facebook: 'https://www.facebook.com/adriane.lasalle'
    },
    {
        name: 'Sam Hess',
        nmls: '#288967',
        phone: '801-388-2279',
        img: './images/sam_hess.webp',
        email: 'sam@keywestfunding.net',
        facebook: 'https://www.facebook.com/samueludy.hess'
    },
    {
        name: 'Terrie Daybell',
        nmls: '#303141',
        phone: '801-695-0475',
        img: './images/terrie_daybell.webp',
        email: 'terriedaybell@msn.com',
        facebook: 'https://www.facebook.com/terrie.daybell'
    },
    {
        name: 'Amy Zabriskie',
        nmls: '#255712',
        phone: '801-301-1154',
        img: './images/amy_zabriskie.webp',
        email: 'a.zabriskie@comcast.net',
        facebook: 'https://www.facebook.com/amy.zabriskie.75?mibextid=ZbWKwL'
    },
    {
        name: 'Natalie Bouy',
        nmls: '#2071744',
        phone: '801-721-5409',
        img: './images/natalie_bouy.webp',
        email: 'nataliebouy@gmail.com',
        facebook: 'https://www.facebook.com/natalie.bouy?mibextid=ZbWKwL'
    },
    {
        name: 'Kaitlyn Hawker',
        nmls: '#2158507',
        phone: '801-633-8942',
        img: './images/kaitlyn_hawker.webp',
        email: 'kaithawker@gmail.com',
        facebook: 'https://www.facebook.com/loanhawker?mibextid=ZbWKwL'
    },
    {
        name: 'Jason Klomp',
        nmls: '#301316',
        phone: '801-645-0172',
        email: 'jasonklomp@gmail.com',
        facebook: 'https://www.facebook.com/willski4food'
    },
    {
        name: 'Coleson Ruple',
        nmls: '#1957798',
        phone: '801-695-1775',
        email: 'colesonruple@gmail.com',
        facebook: 'https://www.facebook.com/coleson.ruple'
    }, 
]